/* eslint-disable quotes */
import Local from "./local";
import Dev from "./dev";
import Hml from "./hml";
import Prod from "./prod";

const env = process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim();

let config = env === "LOCAL" ? Local : Local;
config = env === "DEV" ? Dev : config;
config = env === "HML" ? Hml : config;
config = env === "PROD" ? Prod : config;

export default {
  ...config,
};
