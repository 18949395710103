import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import {
  DEFAULT_STORE, DEFAULT_WHITELABEL, LIST_WHITELABEL,
} from '../actionTypes/branch';
import { viewsModelToModels } from '../mapper/branch';
import updateState from './common/common';

export const setDefaultStore = (data) => async (dispatch) => {
  dispatch({
    type: DEFAULT_STORE,
    payload: { data },
    state: true,
    success: true,
  });
};

export const getDataWhiteLabel = () => async (dispatch) => {
  const base = {
    type: LIST_WHITELABEL,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.STORE.URL}${Config.STORE.WHITE_LABELS}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const setDefaultWhiteLabel = (data) => async (dispatch) => {
  dispatch({
    type: DEFAULT_WHITELABEL,
    payload: { data },
    state: true,
    success: true,
  });
};
