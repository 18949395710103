import React from 'react';
import Logo from '../assets/imgs/pay2b Logo_pay2b-07.png';

function DefaultFooter() {
  return (
    <div className="w-100 d-flex justify-content-center">
      <img src={Logo} alt="Logo" width={75} />
    </div>
  );
}

export default DefaultFooter;
