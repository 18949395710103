import { DETAIL_TRANSACTION, LIST_TRANSACTION } from '../actionTypes/transaction';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  detailTransaction: BASE,
  listTransaction: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DETAIL_TRANSACTION:
      return { ...state, detailTransaction: action.payload };
    case LIST_TRANSACTION:
      return { ...state, listTransaction: action.payload };
    default:
      return state;
  }
};
