export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
      badge: {
        variant: "info",
      },
      groups: ["admin", "dashboard"],
    },
    {
      name: "Agenda",
      url: "/agenda",
      icon: "fa fa-clock-o",
      badge: {
        variant: "info",
      },
      groups: ["schedule", "admin"],
    },
    {
      name: "Vendas",
      url: "/vendas",
      icon: "fa fa-shopping-basket",
      badge: {
        variant: "info",
      },
      groups: ["sales", "admin"],
    },
    {
      name: "Pedidos",
      url: "/pedidos",
      icon: "fa fa-th-list",
      badge: {
        variant: "info",
      },
      groups: ["order-writing", "order-reading", "admin"],
    },
    {
      name: "Assinaturas",
      url: "/assinaturas",
      icon: "fa fa-refresh",
      badge: {
        variant: "info",
      },
      groups: ["subscriptions-writing", "subscriptions-reading", "admin"],
    },
    {
      name: "Gestão de Acessos",
      url: "/gestao_acessos",
      icon: "fa fa-users",
      badge: {
        variant: "info",
      },
      groups: ["admin"],
    },
    {
      name: "Inadimplentes",
      url: "/defaulters",
      icon: "fa fa-id-card",
      badge: {
        variant: "info",
      },
      groups: ["sales", "admin"],
    },
  ],
};
