import {
  LIST_BALANCE, LIST_DASHBOARD, DASHBOARD_CANCEL,
} from '../actionTypes/dashboard';

const BASE = {
  state: false,
  data: null,
};

const INITIAL_STATE = {
  indicators: BASE,
  receivableMonthly: BASE,
  transactionAbstract: BASE,
  receivablesByDateRange: BASE,

  listBalance: BASE,
  listDashboard: BASE,
  dashboardCancel: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_BALANCE:
      return { ...state, listBalance: action.payload };
    case LIST_DASHBOARD:
      return { ...state, listDashboard: action.payload };
    case DASHBOARD_CANCEL:
      return { ...state, dashboardCancel: action.payload };
    default:
      return state;
  }
};
