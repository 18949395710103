import { LIST_RECEIVABLE, LIST_INSTALLMENT, DETAIL_RECEIVABLE } from '../actionTypes/receivable';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostReceivable: BASE,
  listReceivable: BASE_PAGINATION,
  listInstallment: BASE_PAGINATION,
  detailReceivable: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_RECEIVABLE:
      return { ...state, listReceivable: action.payload };
    case LIST_INSTALLMENT:
      return { ...state, listInstallment: action.payload };
    case DETAIL_RECEIVABLE:
      return { ...state, detailReceivable: action.payload };
    default:
      return state;
  }
};
