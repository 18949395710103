import React from 'react';
import { Input } from 'reactstrap';

export function CustomFilterMethod(filter, row) {
  return String(row[filter.id]).toLowerCase()
    .includes(filter.value.toLowerCase());
}

export function CustomTableFilter(dt) {
  const { column, filter, onChange } = dt;

  return (
    <Input
      type="text"
      id={`Filter${column.id}`}
      name={`Filter${column.id}`}
      value={filter ? filter.value : ''}
      onChange={(event) => {
        if (event === null) return;
        onChange(event.target.value);
      }}
    />
  );
}
