import { toast } from 'react-toastify';
import { logout } from '../service/user';
import notify from '../mapper/common/notify';
import { AUTH_TOKEN } from '../actionTypes/auth';
import Config from '../config/environments';
import { localGet, localSet } from './session';

const validateError = (data, defaultError) => {
  const errors = data.data.cause && data.data.cause.errors;

  if (data.config.url === '/oauth/logout' && errors[0].error === 'INVALID_TOKEN') {
    return notify('INACTIVE_USER', 'Deslogado por inatividade.');
  }

  if (errors) {
    return notify(errors[0].error, errors[0].message);
  }

  return defaultError;
};

export default (response) => {
  const base = {};

  const data = response && response.status ? response : response.response;

  if (!data) return base;

  if ((data.message && data.message.includes('Network Error')) || data.status === 503) {
    toast.error('Serviço indisponível no momento, tente novamente mais tarde');
    base.state = false;
    base.data = null;
    base.success = false;
    return base;
  }

  if (data.status === 401 && data.data.cause.errors[0].error === 'FIRST_LOGIN') {
    const error = data.data.cause.errors[0];
    base.state = false;
    base.data = error;
    base.success = false;
    return base;
  }

  if ((data.message && data.message.includes('Request failed with status code 401')) || data.status === 401) {
    toast.error(validateError(data, 'Usuário ou senha inválido'));
    base.state = false;
    base.data = null;
    base.success = false;
    if (data && data.config && data.config.url && data.config.url !== `${Config.USER.URL}${Config.USER.FIRST_LOGIN}`) {
      logout();
    }
    return base;
  }

  if ((data.message && data.message.includes('Request failed with status code 404')) || data.status === 404) {
    toast.error(validateError(data, 'URI não encontrado'));
    base.state = false;
    base.data = null;
    base.success = false;
    return base;
  }

  if ((data.message && data.message.includes('Request failed with status code 500')) || data.status === 500) {
    toast.error(validateError(data, 'Falha no servidor'));
    base.state = false;
    base.data = null;
    base.success = false;
    return base;
  }

  if (data.status < 200 || data.status > 299) {
    toast.error(validateError(data, 'Falha no servidor'));
    base.state = false;
    base.data = null;
    base.success = false;
    return base;
  }

  data.success = true;

  if (data && data.data && data.data.accessToken) {
    const auth = localGet(AUTH_TOKEN);
    if (auth) {
      auth.AccessToken = data.data.accessToken;
      localSet(AUTH_TOKEN, auth, Config.EXPIRATION_AUTH);
    }
  }

  return data;
};
