import Model from '../model/branch';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Uuid = view.uuid;
    model.FantasyName = view.name;
    model.Active = view.active;
    model.Booking = view.booking;
    model.Integrator = view.integrator;
    model.HeadOffice = view.headOffice;
    model.Online = view.online;

    return model;
  } catch (error) {
    throw new Error(`BranchMapper - ViewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`BranchMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    if (model.Uuid) {
      view.uuid = model.Uuid;
    }

    view.number = model.Number;
    view.name = model.Name;
    view.initials = model.Initials;

    return view;
  } catch (error) {
    throw new Error(`BranchMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`BranchMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Uuid = view.Uuid;
    model.Number = view.Number;
    model.Name = view.Name;
    model.Initials = view.Initials;

    return model;
  } catch (error) {
    throw new Error(`BranchMapper - viewModelToState = ${error}`);
  }
};
