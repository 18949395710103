import { cnpj, cpf } from 'cpf-cnpj-validator';
import validator from 'validator';

export const cnpjValidator = () => {
  const inputs = document.getElementsByClassName('cnpj-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      nameInput.addEventListener('input', (e) => {
        const { valid } = e.target.validity;
        const { required } = e.target;
        const size = nameInput.value.length;

        if (valid && required && !cnpj.isValid(nameInput.value)) {
          nameInput.setCustomValidity('CNPJ inválido');
          return;
        }

        if (valid && !required && size > 0 && !cnpj.isValid(nameInput.value)) {
          nameInput.setCustomValidity('CNPJ inválido');
          return;
        }

        nameInput.setCustomValidity('');
      });
    }
  }
};

export const fullNameValidator = () => {
  const inputs = document.getElementsByClassName('full-name-validator');

  if (inputs.length > 0) {
    const nameInput = inputs[0];
    const pattern = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
    const hasNumber = /\d/;

    nameInput.addEventListener('input', (e) => {
      const { required, value } = e.target;
      const valid = pattern.test(value && value.trim());
      const size = value.length;
      let invalidSurname = false;

      value.split(' ').forEach((v, idx) => {
        if (v && v.trim().length < 2 && idx > 0) {
          invalidSurname = true;
        } else {
          invalidSurname = false;
        }
      });

      if (!valid && required && size > 0) {
        if (hasNumber.test(value)) {
          nameInput.setCustomValidity('Nome não deve conter números');
          return;
        }

        if (invalidSurname) {
          nameInput.setCustomValidity('Sobrenome deve conter ao menos 2 caracteres');
          return;
        }

        if (value.split(' ')[0].length < 2) {
          nameInput.setCustomValidity('Primeiro nome deve conter ao menos 2 caracteres');
          return;
        }

        if (value.split(' ').length < 2) {
          nameInput.setCustomValidity('É necessário inserir sobrenome');
          return;
        }
      }

      nameInput.setCustomValidity('');
    });
  }
};

export const checkboxValidator = (checked) => {
  const inputs = document.getElementsByClassName('checkbox-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      let idx = 0;

      if (index >= 1 && index <= 2) {
        idx = 1;

        if (checked[idx] === '') {
          nameInput.setCustomValidity('Selecione ao menos uma opção por permissão');
        } else {
          nameInput.setCustomValidity('');
        }
      }

      if (index >= 3 && index <= 4) {
        idx = 2;

        if (checked[idx] === '') {
          nameInput.setCustomValidity('Selecione ao menos uma opção por permissão');
        } else {
          nameInput.setCustomValidity('');
        }
      }

      if (index >= 5 && index <= 6) {
        idx = 3;

        if (checked[idx] === '') {
          nameInput.setCustomValidity('Selecione ao menos uma opção por permissão');
        } else {
          nameInput.setCustomValidity('');
        }
      }

      if (index >= 7 && index <= 9) {
        idx = 4;

        if (checked[idx] === '') {
          nameInput.setCustomValidity('Selecione ao menos uma opção por permissão');
        } else {
          nameInput.setCustomValidity('');
        }
      }

      if (index >= 10 && index <= 12) {
        idx = 5;

        if (checked[idx] === '') {
          nameInput.setCustomValidity('Selecione ao menos uma opção por permissão');
        } else {
          nameInput.setCustomValidity('');
        }
      }
    }
  }
};

export const cpfValidator = () => {
  const inputs = document.getElementsByClassName('cpf-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      nameInput.addEventListener('input', (e) => {
        const { valid } = e.target.validity;
        const { required } = e.target;
        const size = nameInput.value.length;

        if (valid && required && !cpf.isValid(nameInput.value)) {
          nameInput.setCustomValidity('CPF inválido');
          return;
        }

        if (valid && !required && size > 0 && !cnpj.isValid(nameInput.value)) {
          nameInput.setCustomValidity('CPF inválido');
          return;
        }

        nameInput.setCustomValidity('');
      });
    }
  }
};

export const passwordValidator = (password, confirmPassword) => {
  const inputs = document.getElementsByClassName('password-validator');

  const nameInput = inputs[0];

  if (!validator.matches(password, /.*[0-9].*/g)) {
    nameInput.setCustomValidity('A senha precisa ter pelo menos um número');
    return;
  }

  if (!validator.matches(password, /[A-Z]/g)) {
    nameInput.setCustomValidity('A senha precisa ter pelo menos uma letra maiúscula');
    return;
  }

  if (!validator.matches(password, /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g)) {
    nameInput.setCustomValidity('A senha precisa ter pelo menos um caractere especial');
    return;
  }

  if (!validator.isLength(password, { min: 8 })) {
    nameInput.setCustomValidity('A senha precisa ter no mínimo 8 caracteres');
    return;
  }

  if (!validator.equals(password, confirmPassword)) {
    nameInput.setCustomValidity('A senha não corresponde');
    return;
  }

  nameInput.setCustomValidity('');
};
