import { localGet, localRemove } from './session';
import { AUTH_TOKEN, IS_LOGGED } from '../actionTypes/auth';

export default (data) => {
  const config = data;
  const authData = localGet(AUTH_TOKEN);

  if (!authData || !authData.AccessToken) {
    localRemove(AUTH_TOKEN);
    localRemove(IS_LOGGED);
  }

  if (authData && authData.AccessToken) {
    config.headers['x-access-token'] = authData.AccessToken;
    config.headers['x-merchant-id'] = authData.MerchantId;
  }

  return config;
};
