export default {
  IsAdmin: '',
  Uuid: '',
  Email: '',
  Name: '',
  ProviderUserId: '',
  MerchantId: '',
  Error: '',
  AccessToken: '',
  CreatedAt: '',
  Groups: [],
};
