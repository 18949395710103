export default {
  Email: '',
  Code: '',
  Password: '',
  ConfirmPassword: '',
  hasNumbers: false,
  hasUpperCase: false,
  hasSpecialChar: false,
  isLongEnough: false,
};
