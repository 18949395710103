import { POST_GROUP, LIST_GROUP } from '../actionTypes/group';

const BASE = {
  state: false,
  data: null,
};

const INITIAL_STATE = {
  statePostGroup: BASE,
  listGroup: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_GROUP:
      return { ...state, statePostGroup: action.payload };
    case LIST_GROUP:
      return { ...state, listGroup: action.payload };
    default:
      return state;
  }
};
