import { LIST_SUBSCRIPTION, PUT_SUBSCRIPTION_REFOUND, PUT_SUBSCRIPTION_CANCEL } from '../actionTypes/subcription';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  listSubscription: BASE_PAGINATION,
  stateSubscriptionRefound: BASE,
  stateSubscriptionCancel: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_SUBSCRIPTION:
      return { ...state, listSubscription: action.payload };
    case PUT_SUBSCRIPTION_REFOUND:
      return { ...state, stateSubscriptionRefound: action.payload };
    case PUT_SUBSCRIPTION_CANCEL:
      return { ...state, stateSubscriptionCancel: action.payload };
    default:
      return state;
  }
};
