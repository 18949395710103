/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint no-extend-native: ["error", { "exceptions": ["String", "Number"] }] */
import minMaxRange from "./utils";

export const DocumentFormat = (document) => {
  let value = document.replace(/[^\d]/g, "");

  if (!value) return "-";

  if (value.length <= 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  value = value.replace(
    /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
    "$1.$2.$3/$4-$5"
  );

  return value;
};

export const CpfMask = (value) =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

export const CnpjMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/, "$1.$2");
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
  v = v.replace(/(\d{4})(\d)/, "$1-$2");

  return v;
};

export const DocumentMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");

  if (v.length <= 11) {
    return CpfMask(v);
  }
  return CnpjMask(v);
};

export const PhoneCompleteMask = (value) => {
  let v = value;
  v = v.replace(/\D/g, "");

  if (v.length <= 10) {
    v = v.replace(/(\d{0})(\d)/, "($1$2");
    v = v.replace(/(\d{2})(\d)/, "$1) $2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  } else {
    v = v.replace(/(\d{0})(\d)/, "($1$2");
    v = v.replace(/(\d{2})(\d)/, "$1) $2");
    v = v.replace(/(\d{5})(\d)/, "$1-$2");
  }

  return v;
};

const brlFormatterNative = new Intl.NumberFormat("pt-Br", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export const brlFormatter = {
  format: (value) => brlFormatterNative.format(value / 100),
};

export function formatCPF(document) {
  return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatCNPJ(document) {
  return document.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
}

export function formatCEP(document) {
  return document.replace(/(\d{5})/, "$1-");
}

export function formatPhone(document) {
  if (document.length === 8) {
    return document.replace(/(\d{4})/, "$1-");
  }
  return document.replace(/(\d{5})/, "$1-");
}

export function mask(number, masks) {
  number = number.replace(/[^0-9]/g, "");
  let numberPos = 0;
  return number
    .split("")
    .map((num, i) => {
      if (masks[i + numberPos] === "0") {
        return num;
      }
      return masks[i + numberPos++] + num;
    })
    .join("");
}

String.prototype.nameMask = function () {
  return this.replace(/[^A-Za-zÀ-Úà-ú\s]/g, "");
};

String.prototype.cpfMask = function () {
  return mask(this, "000.000.000-00");
};

String.prototype.cnpjMask = function () {
  return mask(this, "00.000.000/0000-00");
};

String.prototype.dateMask = function () {
  const dateSplit = this.split(/-/);
  let day = dateSplit[2];
  let month = dateSplit[1];
  let year = dateSplit[0];

  if (day && day.length === 2) {
    const max = month === "02" ? 29 : parseInt(month, 10) % 2 === 0 ? 30 : 31;
    const nDay = minMaxRange(parseInt(day, 10), 1, max);
    day = nDay < 10 ? `0${nDay}` : nDay;
  }

  if (month && month.length === 2) {
    const nMonth = minMaxRange(parseInt(month, 10), 1, 12);
    month = nMonth < 10 ? `0${nMonth}` : nMonth;
  }

  if (year && year.length === 4) {
    const min = new Date().getFullYear() - 120;
    const max = new Date().getFullYear() - 18;
    year = minMaxRange(parseInt(year, 10), min, max);
  }

  return mask(`${day}${month}${year}`, "00/00/0000");
};

String.prototype.zipcodeMask = function () {
  return mask(this, "00000-000");
};

String.prototype.phoneMask = function () {
  const cleanPhone = this.replace(/[^0-9]/g, "");
  return cleanPhone.length <= 8
    ? mask(this, "0000-0000")
    : mask(this, "00000-0000");
};

Number.prototype.BRLCurrency = function () {
  return brlFormatterNative.format(this / 100);
};
