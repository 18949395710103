import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  Label,
  Media,
} from 'reactstrap';
import validator from 'validator';
import { toast } from 'react-toastify';
import { resetDataPassword } from '../../actions/user';
import Model from '../../model/reset';
import { modelToResetViewModel } from '../../mapper/user';
import RESET from '../../const/reset';
import { passwordValidator } from '../../lib/customValidade';

class Recover extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.state = { ...Model, Redirected: false, Width: window.innerWidth };
  }

  componentDidMount() {
    const { history } = this.props;
    const { location } = history;
    const { state } = location;

    if (state && state.Email) {
      this.setState({ Email: state.Email });
    }
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { authUser, history } = nextProps;
    const { Redirected } = nextState;
    const { Password, ConfirmPassword } = this.state;

    if (authUser && authUser.state === false && Redirected) {
      if (authUser && authUser.success === true) {
        toast.success(RESET.NOTIFY_SUCCESS);
        history.push({
          pathname: '/login',
        });
      }
    }

    if (nextState.Password !== Password || nextState.ConfirmPassword !== ConfirmPassword) {
      passwordValidator(nextState.Password, nextState.ConfirmPassword);
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  handleSubmit(event) {
    event.preventDefault();

    const p = this.props;
    const state = modelToResetViewModel(this.state);

    p.resetDataPassword(state);
    this.setState({ Redirected: true });
  }

  render() {
    const {
      Password, Email, Code, Width,
    } = this.state;

    let {
      hasNumbers, hasUpperCase, hasSpecialChar, isLongEnough,
    } = this.state;
    const isMobile = Width < 999;

    hasNumbers = validator.matches(Password, /.*[0-9].*/g);
    hasUpperCase = validator.matches(Password, /[A-Z]/g);
    hasSpecialChar = validator.matches(Password, /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g);
    isLongEnough = validator.isLength(Password, { min: 8 });

    return (
      <div className="app back-logo flex-row align-items-center">
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center">
              <Col md="6">
                <CardGroup>
                  <Card className="p-4 border-top">
                    <CardBody>
                      <Row className={`${isMobile ? 'mb-2' : ''}`}>
                        <Col xs={isMobile ? 6 : 5} className={`d-flex justify-content-end ${isMobile ? '' : 'p-0'}`}>
                          <Media
                            className="logo"
                            object
                            src="/assets/imgs/pay2b Logo_pay2b-07.png"
                            alt="Logo"
                            style={{ width: '100px' }}
                          />
                        </Col>
                        <Col xs={isMobile ? 6 : 7} className="d-flex flex-column justify-content-center p-0">
                          <span className="title">{RESET.TITLE}</span>
                          <span className="sub-title">{RESET.SUB_TITLE}</span>
                        </Col>
                      </Row>
                      <Row className="justify-content-center text-center">
                        <Col md="12">
                          <h1>{RESET.RESET_SUB_TEXT}</h1>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="justify-content-center text-center">
                        <Col md="12 mb-3">
                          <Label style={{ fontSize: '18px' }}>{RESET.TITLE_VALIDATION}</Label>
                          <ul className="validation">
                            <li className={`validation--item ${hasUpperCase ? 'valid' : 'invalid'}`}>Uma letra maiúscula</li>
                            <li className={`validation--item ${hasSpecialChar ? 'valid' : 'invalid'}`}>Um caractere especial</li>
                            <li className={`validation--item ${hasNumbers ? 'valid' : 'invalid'}`}>Um número</li>
                            <li className={`validation--item ${isLongEnough ? 'valid' : 'invalid'}`}>8 caracteres</li>
                          </ul>
                        </Col>
                      </Row>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="email"
                          placeholder="Email"
                          required="required"
                          id="Email"
                          name="Email"
                          value={Email}
                          maxLength="50"
                          onChange={(e) => this.setState({ Email: e.target.value })}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-key" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Código recebido via email"
                          required="required"
                          id="Code"
                          name="Code"
                          value={Code}
                          maxLength={6}
                          onChange={(e) => this.setState({ Code: e.target.value })}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Senha"
                          required="required"
                          id="Password"
                          name="Password"
                          maxLength="256"
                          className="password-validator"
                          onChange={(e) => this.setState({ Password: e.target.value })}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Confirmar a senha"
                          required="required"
                          id="ConfirmPassword"
                          name="ConfirmPassword"
                          maxLength="256"
                          onChange={(e) => this.setState({ ConfirmPassword: e.target.value })}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="12">
                          <Button color="primary" className="px-4 bg-p2b border-0 mx-0" style={{ width: '100%' }}>
                            {RESET.RESET_BUTTON}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

Recover.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        Email: PropTypes.string,
      }),
    }),
  }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  resetDataPassword: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    data: PropTypes.shape({ }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    resetDataPassword,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(Recover);
