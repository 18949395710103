import {
  DEFAULT_STORE, DEFAULT_WHITELABEL, LIST_WHITELABEL,
} from '../actionTypes/branch';

const BASE = {
  state: false,
  data: null,
};

const INITIAL_STATE = {
  defaultStore: BASE,
  listWhitelabel: BASE,
  defaultWhitelabel: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEFAULT_STORE:
      return { ...state, defaultStore: action.payload };
    case DEFAULT_WHITELABEL:
      return { ...state, defaultWhitelabel: action.payload };
    case LIST_WHITELABEL:
      return { ...state, listWhitelabel: action.payload };
    default:
      return state;
  }
};
