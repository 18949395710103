import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';

export default function Loading(props) {
  const { msg } = props;
  return (
    <div className="float-loading">
      <div className="loading">
        <center>
          <ReactLoading color="#20a8d8" height="70px" width="100px" />
          <span>{msg}</span>
        </center>
      </div>
    </div>
  );
}

Loading.propTypes = {
  msg: PropTypes.string.isRequired,
};
